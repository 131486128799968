export default {
    theme:{
        defaultTheme: 'dark',
        themes:{
            dark:{
                colors: {
                    "primary":"#F8C25F",
                    "accent":"#424242",
                    "secondary":"#ff8f00",
                    "info":"#26a69a",
                    "warning":"#ffc107",
                    "error":"#dd2c00",
                    "success":"#00e676"
                }
            },

            light:{
                colors:{
                    "primary":"#F8C25F",
                    "accent":"#424242",
                    "secondary":"#ff8f00",
                    "info":"#26a69a",
                    "warning":"#ffc107",
                    "error":"#dd2c00",
                    "success":"#00e676"
                }
            }
        }
    }
}
