<template>
	<div>
		<v-row no-gutters>
			<!-- SIDEBAR SECTION -->
			<v-col cols="12" md="4">
				<v-card
					border
					:max-width="this.$vuetify.display.mobile ? '' : 310"
					density="comfortable"
					style="position: sticky; top: 50px"
					class="rounded-xl mx-auto"
				>
					<template v-slot:append>
						<v-avatar
							class="hidden-md-and-up ml-15 mt-n10 mr-n2"
							@click="extended = !extended"
						>
							<v-icon color="primary">mdi-menu</v-icon>
						</v-avatar>
					</template>

					<template v-if="this.$vuetify.display.mobile" v-slot:title>
						<div class="mt-2">{{ profile[0].title }}</div>
					</template>

					<!-- medium and big screens -->
					<template v-else v-slot:title>
						<div class="mt-7 mx-auto">
							<v-avatar class="mx-6 my-2" size="200">
								<v-img
									src="@/assets/images/cube.png"
								></v-img>
							</v-avatar>
							<v-card density="comfortable" class="mx-auto" elevation="0">
								<template v-slot:title>
									<div
										class="mt-4 subtitle font-weight-black text-uppercase text-center"
									>
										{{ profile[0].title }}
									</div>
								</template>

								<template v-slot:subtitle>
									<div class="mt-1 subtitle text-center">
										{{ profile[0].subtitle }}
									</div>
								</template>
							</v-card>
						</div>
					</template>

					<template
						v-if="this.$vuetify.display.mobile"
						v-slot:subtitle
					>
						<div class="mt-1">{{ profile[0].subtitle }}</div>
					</template>

					<template v-slot:prepend>
						<v-avatar
							v-if="this.$vuetify.display.mobile"
							class="mt-2"
							size="70"
						>
							<v-img src="@/assets/images/cube.png"></v-img>
						</v-avatar>
					</template>

					<template v-slot:text>
						<v-list lines="two" v-if="extended" v-for="(item, index) in profile" :key="index">
							<v-divider class="ma-2"></v-divider>
							<v-list-item
								:key="index"
								class="mt-4 mb-2"
								append-ico
								title="Phone"
								:subtitle="item.phoneNumber"
							>
								<template v-slot:prepend>
									<v-avatar class="elevation-2">
										<v-icon
											icon="mdi-phone"
											color="primary"
										></v-icon>
									</v-avatar>
								</template>
							</v-list-item>

							<v-list-item
								class="mb-2"
								:key="index"
								title="Email"
								:subtitle="item.email"
							>
								<template v-slot:prepend>
									<v-avatar class="elevation-2">
										<v-icon
											icon="mdi-email"
											color="primary"
										></v-icon>
									</v-avatar>
								</template>
							</v-list-item>

							<v-list-item :key="index" title="Location" :subtitle="item.location">
								<template v-slot:prepend>
									<v-avatar class="elevation-2">
										<v-icon
											icon="mdi-map-marker"
											color="primary"
										></v-icon>
									</v-avatar>
								</template>
							</v-list-item>
							<v-divider
								v-if="this.$vuetify.display.mobile"
								class="mt-10 mx-2"
							></v-divider>
						</v-list>
						<v-card-actions v-if="extended">
							<div class="mx-auto">
								<v-btn
									v-for="icon in icons"
									:key="icon.link"
									class="mx-auto"
									:icon="icon.icon"
									color=""
									variant="text"
									:href="icon.link"
									target="_blank"
								></v-btn>
							</div>
							<div>
								<v-icon @click="toggleTheme" :icon="(theme.global.name === 'dark') ? 'mdi-weather-sunny' : 'mdi-weather-night'" color="primary"></v-icon>
							</div>
						</v-card-actions>
					</template>
				</v-card>
			</v-col>

			<!-- MAIN PAGES SECTION -->
			<v-col cols="12" md="8">
				<v-card
					max-width="900"
					:class="
						this.$vuetify.display.mobile
							? 'mx-auto mt-2 rounded-xl'
							: 'ml-n11 rounded-xl'
					"
					border
					density="comfortable"
				>
					<template v-slot:title>
						<v-row class="mt-n4">
							<v-col class="mt-4 px-12" cols="12" md="3">
								<div
									class="subtitle custom-underlined-text font-weight-black text-uppercase text-center"
								>
									{{ activeDrawer ? activeDrawer : ''}}
								</div>
							</v-col>
							<v-col cols="9">
								<v-toolbar
									border
									density="compact"
									color="transparent"
									class="rounded-bs-xl ml-1 hidden-sm-and-down"
								>
									<TopNavComponent></TopNavComponent>
								</v-toolbar>
							</v-col>
						</v-row>
					</template>

					<template v-slot:text>
						<v-main :class="this.$vuetify.display.mobile ? '' : ''">
							<v-container fluid>
								<div> <router-view /> </div>
							</v-container>
						</v-main>
					</template>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import TopNavComponent from "../components/FooterNav.vue";
import { useSelectedDrawerStore } from "../store/selectDrawer";
import { useTheme } from 'vuetify'

export default {
	name: "ProfileComponent",
	
	components: {
		TopNavComponent,
	},

	data: () => ({
		extended: true,
		theme: useTheme(),
		comments: [
			{
				avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
				title: "Ali Connors",
				subtitle: `I'll be in your neighborhood`,
			},
		],

		profile: [
			{
				avatar: "../assets/images/sample.svg",
				title: "Mike Nzioki",
				subtitle: "Software Developer",
				email: "mikenzioki65@gmail.com",
				phoneNumber: "+254 747 351 772",
				BirthDay: "April 22, 1998",
				location: "Nairobi, Kenya",
			},
		],

		icons: [ 
			{
				icon: "mdi-github", 
				link:"https://github.com/mike-nzioki"
			}, 
			{
				icon:"mdi-gitlab", 
				link:"https://gitlab.com/Mikie.Nzioki"
			},
			{
				icon:"mdi-linkedin", 
				link: "https://www.linkedin.com/in/mike-in-tech/",
			},
			{
				 icon:"mdi-instagram", 
				 link: "https://instagram.com/mikie.dev",
			}
		]
	}),

	computed: {
		// activate drawer
		activeDrawer() {
			const selectedDrawerStore = useSelectedDrawerStore();
			return selectedDrawerStore.activeDrawer;
		},
	},

	methods: {
    	toggleTheme() {
			this.theme.global.name = this.theme.global.current.dark ? 'light' : 'dark'
    	},
    },
};
</script>
<style>
.custom-underlined-text {
	position: relative;
	text-decoration: none;
}

.custom-underlined-text::after {
	content: "";
	position: absolute;
	bottom: -2px;
	left: 0;
	width: 100%;
	height: 5px;
	background-color: #f8c25f;
}
</style>