import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children:[
      {
        path: '/',
        name: 'About',
        component: function () {
          return import('../views/AboutView.vue')
        }
      },
      {
        path: '/login',
        name: 'BlogPost',
        component: function () {
          return import('../views/BlogPost.vue')
        }
      },
      {
        path: '/blog',
        name: 'Blog',
        component: function () {
          return import('../views/BlogView.vue')
        }
      },
      {
        path: '/resume',
        name: 'Resume',
        component: function () {
          return import('../views/ResumeView.vue')
        }
      },      
      {
        path: '/contact',
        name: 'Contact',
        component: function () {
          return import('../views/ContactView.vue')
        }
      },
      {
        path: '/portfolio',
        name: 'Portfolio',
        component: function () {
          return import('../views/PortfolioView.vue')
        }
      },


    ]
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
