<template>
  <v-snackbar
    v-model="alert.show"
    :timeout="2000"
    :color="alert.color"
    location="top right"
    class="mt-2"
  >
    <v-icon :icon="alert.icon" size="x-large" class="mr-1"></v-icon>
    {{ alert.message }}
  </v-snackbar>
</template>

<script>
import { computed, onMounted, onUnmounted } from 'vue';
import { useAlertStore } from '@/store/alertStore.js';;

export default {
  name: 'SnacBarComponent',
  setup() {
    const alertStore = useAlertStore();
    
    const hideAlert = () => {
      alertStore.clear();
    };
    
    const showAlert = () => {
      setTimeout(() => {
        alertStore.clear();
      }, 3000);
    };
    
    const alert = computed(() => alertStore.$state);
    
    onMounted(() => {
      alertStore.$subscribe((mutation) => {
        if (mutation.type === 'clear') {
          showAlert();
        }
      });
    });

    onUnmounted(() => {
      alertStore.$unsubscribe();
    });

    return {
      alert,
      hideAlert,
      showAlert,
    };
  },
};
</script>

<style scoped>
.top-right-snackbar {
  position: fixed !important;
  top: 0% !important;
  right: 20px !important;
}
</style>
