import { defineStore } from 'pinia';

export const useAlertStore = defineStore({
  id: 'alert',
  state: () => ({
    color: '',
    message: '',
    icon: '',
    show: false,
  }),
  actions: {
    success(message) {
      this.color = 'success';
      this.message = message;
      this.icon = 'mdi-check-circle';
      this.show = true;
    },
    error(message) {
      this.color = 'error';
      this.message = message;
      this.icon = 'mdi-close-circle';
      this.show = true;
    },
    info(message) {
      this.color = 'info';
      this.message = message;
      this.icon = 'mdi-information-slab-circle';
      this.show = true;
    },
    clear() {
      this.color = '';
      this.message = '';
      this.icon = '';
      this.show = false;
    },
  },
  getters: {
    getColor() {
      return this.color;
    },
    getMessage() {
      return this.message;
    },
    getShow() {
      return this.show;
    },
    getIcon() {
      return this.icon;
    },
  },
});
