// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify, ThemeDefinition } from 'vuetify';
import options from './options.js';

export default createVuetify({
  theme: options.theme
});


