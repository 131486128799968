<template>
  <v-app>
    <v-main class="ma-1">
      <SnacBarComponent/>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import SnacBarComponent from '@/components/SnacBarComponent'

export default {
  name: 'App',
  components: {
		SnacBarComponent,
	},

  data: () => ({
    //
  }),
}
</script>
