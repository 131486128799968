<template>
	<v-container fluid :class="this.$vuetify.display.mobile ? 'mt-2' : 'mt-11'">
		<ProfileComponent></ProfileComponent>
		<v-footer density="compact" class="mt-2 hidden-md-and-up" border app>
			<FooterComponent></FooterComponent>
		</v-footer>
	</v-container>
</template>

<script>
import { defineComponent } from "vue";

// Components
import ProfileComponent from "../components/LayoutComponent.vue";
import FooterComponent from "../components/FooterNav.vue"

export default defineComponent({
	name: "HomeComponent",
	components: {
		ProfileComponent,
		FooterComponent,
	},

});
</script>