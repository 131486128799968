<template>
	<div class="px-4 py-2 text-center w-100">
		<v-list class="d-flex mx-auto justify-space-between">
			<v-list-item
				v-for="(item, index) in drawer"
				:key="index"
				:value="item.name"
				:to="item.route"
				rounded="shaped"
				:color="item.color"
				:class="['mx-1', 'mb-1']"
				@click="setActiveDrawerHandler(item)"
			>
				<template v-slot:title>
					{{ item.name }}
				</template>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>
import { useSelectedDrawerStore } from "../store/selectDrawer";

export default {
	name: "FooterComponent",
	data: () => ({
		activeItemIndex: 0,
		drawer: [
			{
				name: "About Me",
				route: "/",
				color: "primary",
			},
			{
				name: "Resume",
				route: "/resume",
				color: "primary",
			},
			{
				name: "Portfolio",
				route: "/portfolio",
				color: "primary",
			},
			{
				name: "Blog",
				route: "/blog",
				color: "primary",
			},
			{
				name: "Contact Me",
				route: "/contact",
				color: "primary",
			},
			{
				name: "Login",
				route: "/login",
				color: "primary",
			},
		],
	}),

	computed: {
		activeDrawer() {
			const selectedDrawerStore = useSelectedDrawerStore();
			return selectedDrawerStore.activeDrawer;
		},
	},

	mounted() {
		const selectedDrawerStore = useSelectedDrawerStore();

		if (!selectedDrawerStore.activeDrawer && this.drawer.length > 0) {
			this.setActiveDrawerHandler(this.drawer[0]);
		}
	},

	methods: {
		setActiveDrawerHandler(item) {
			const selectedDrawerStore = useSelectedDrawerStore();
			selectedDrawerStore.setActiveDrawer(item);
		},
	},
};
</script>
