import { defineStore } from 'pinia'
const STORAGE_KEY = 'selectedDrawer';

export const useSelectedDrawerStore = defineStore('selectedDrawer', {
    state: () => ({
      activeDrawer: localStorage.getItem(STORAGE_KEY) || null,
    }),
    actions: {
      setActiveDrawer(item) {
        this.activeDrawer = item.name;
        localStorage.setItem(STORAGE_KEY, item.name);
      },
    },

    
});